.fixed-header-container {
  width: 60%;
  margin: auto;
  margin-top: 2em;
  height: 80px;
}

.fixed-header {
  position: fixed;
  width: 60%;
  margin: auto;
}

body {
  overflow-y: hidden;
}

.section-container {
  overflow-y: scroll;
  position: fixed;
  top: 80px;
  height: calc(100% - 80px);
  padding-bottom: 40px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #bebebe;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}

.redirect-btn {
  position: fixed !important;
  right: 7em;
  top: 1.5em;
}

.pagination {
  width: 90%;
  margin: auto;
}
