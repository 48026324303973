.markdown-cotainer {
  /* border: 2px solid transparent;
  box-shadow: 3px 3px 10px #666; */
  width: 80%;
  margin: auto;
  border-radius: 0.5em;
  /* margin-top: 3em;
  background: #f0f0f0; */
}

p:hover {
  color: #00e3e3;
}

p {
  color: rgba(0, 0, 0, 0.65);
  padding: 0 5% 0 5%;
}

hr {
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid #e1e4e5;
  margin: 24px 0;
  padding: 0;
  width: 90%;
  margin: auto;
}

ol,
ul {
  width: 90%;
  margin: auto;
}
.markdown-cotainer p {
  text-align: left;
}

.markdown {
  padding: 2em 0 2em 0;
  text-align: left;
  margin: auto;
}

blockquote {
  padding: 0 5% 0 5%;
}

.markdown pre {
  width: 90%;
  margin: auto;
  padding: 0.3em 0 0.3em 0;
}
