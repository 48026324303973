.code-cotainer {
  /* border: 2px solid transparent;
  box-shadow: 3px 3px 10px #666; */
  width: 70%;
  margin: auto;
  border-radius: 0.5em;
  /* margin-top: 3em;
  background: #282c34; */
}

.code-cotainer p {
  padding-left: 10%;
  text-align: left;
}

.code {
  padding: 2em 0 2em 0;
  text-align: left;
  margin: auto;
}

code div {
  width: 95%;
  margin: auto;
  padding: 1em 0 1em 0;
}
