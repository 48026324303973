.raw-container {
  /* border: 2px solid transparent;
  box-shadow: 3px 3px 10px #666; */
  width: 80%;
  margin: auto;
  border-radius: 0.5em;
  /* margin-top: 3em; */
}

.raw {
  padding: 2em 0 2em 0;
  text-align: left;
  width: 90%;
  margin: auto;
}

.answer-area {
  background: rgb(247, 247, 247) !important;
}
